import React, { useContext, useEffect, useState } from "react";
import {
  encryptSession,
  ResetTwoFactoAuthentication,
  UserLogin,
} from "../../api";
import { AppContext } from "../../AppContext";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Forgot from "../Password/Forgot";

var firstDay = new Date(new Date().valueOf() - 1000 * 60 * 60 * 164);
var lastDay = new Date();

function SignUp() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { context, setContext } = useContext(AppContext);
  const [values, setValues] = useState({ password: "", email: "" });
  const [twoFA, setTwoFA] = useState(0);
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [showTwoFactorOptions, setShowTwoFactorOptions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgot, setForgot] = useState(false);

  const onSuccess = () => {
    setIsLoggedIn(true);
  };

  const {
    loading: assetloading,
    result,
    handleFormSubmit: HandleLogin,
  } = UserLogin({ ...values }, [onSuccess]);

  useEffect(() => {
    setIsTwoFactorEnabled(+result?.TwoFA === 1);
    setTwoFA(+result?.TwoFA);
  }, [result]);

  function SetDefaultRange(locations) {
    encryptSession.setItem("cn", locations[0].TradingName);
    encryptSession.setItem("sl", locations[0].Slogan);
    encryptSession.setItem("cim", locations[0].ImageID);
    encryptSession.setItem("locd", locations[0]);
    setContext({
      ...context,
      VendorName: locations[0]?.TradingName,
      VendorImage: locations[0]?.ImageID,
      VendorLocationID: locations[0]?.VendorLocationID,
      VendorID: locations[0]?.VendorID,
      VendorStatusID: locations[0]?.VendorStatusIDF,
    });
    localStorage.setItem(
      "values",
      JSON.stringify({
        VendorLocationID: locations[0].VendorLocationID,
        VendorLocationName: locations[0].VendorLocationName,
        DateRange: [firstDay, lastDay],
        startDate: moment(firstDay).format("YYYY-MM-DD"),
        endDate: moment(lastDay).format("YYYY-MM-DD"),
      })
    );
  }

  const handleLogin = (e) => {
    e.preventDefault();
    HandleLogin();
    setShowTwoFactorOptions(true);
  };

  function decodePasscode(encodedPasscode) {
    const decodedPasscode = atob(encodedPasscode);
    return decodedPasscode;
  }

  useEffect(() => {
    if (result && +twoFA !== result?.twoFA) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFA]);

  const handleTwoFactorSubmit = async (e) => {
    e.preventDefault();

    const decodedPasscode = decodePasscode(result?.TwoFACode);
    if (decodedPasscode?.toLowerCase() === twoFactorCode?.toLowerCase()) {
      if (twoFA !== result?.TwoFA) {
        HandleResetTwoFA();
      } else {
        handleLoginSuccess();
      }
    } else {
      toast.error("The code does not match, please retry");
    }
  };

  const handleLoginSuccess = () => {
    localStorage.setItem("profile", result.UserID);
    encryptSession.setItem("tk", result.UserToken);
    encryptSession.setItem("us", result.UserID);
    encryptSession.setItem("ci", result.VendorID);
    encryptSession.setItem("pi", result.VendorParentID);
    encryptSession.setItem("ust", result.UserTypeID);
    encryptSession.setItem("usn", result.FirstName);
    encryptSession.setItem("phn", result.PhoneNumber);
    encryptSession.setItem("loc", result.AssignedStationArray);
    SetDefaultRange(result.AssignedStationArray);
    encryptSession.setItem("u", result);
    encryptSession.setItem("type", result.VendorTypeID);
    encryptSession.setItem("pro", result.VendorCratoProductArray);
    //VendorCratoProductArray
    localStorage.setItem("tpy", 1);
    localStorage.setItem("index", 0);
    window.location.href =
      result.VendorTypeID === 4 ? "/loyalty/dashboard" : "/dashboard/dashboard";
  };

  const handleContinue = async () => {
    try {
      if (twoFA !== result?.TwoFA) {
        HandleResetTwoFA();
      } else {
        toast.info("Proceeded without two-factor authentication");
        handleLoginSuccess();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const { loading: resetloading, handleFormSubmit: HandleResetTwoFA } =
    ResetTwoFactoAuthentication({ twoFA: twoFA, UserID: result?.UserID }, [
      handleLoginSuccess,
    ]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  const styles = {
    body: {
      backgroundColor: "#2c3d4e", // dark blue
      color: "#7fa7ba", // grey text for less prominence
      fontFamily: "Helvetica Neue, sans-serif",
      fontWeight: 300,
      fontSize: "1rem",
      lineHeight: 1.5,
      margin: 0,
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    grid: {
      maxWidth: "25rem",
      width: "100%",
      margin: "0 auto",
    },
    register: {
      boxShadow: "0 0 250px #000",
      textAlign: "center",
      padding: "4rem 2rem",
      backgroundColor: "#2c3d4e", // dark blue background
      borderRadius: "10px",
    },
    formField: {
      marginBottom: "1rem",
      position: "relative", // To position the visibility icon inside input
    },
    input: {
      border: "1px solid #44dc94", // green border
      borderRadius: "999px",
      backgroundColor: "transparent",
      textAlign: "center",
      padding: ".5rem 1rem",
      width: "100%",
      outline: "0",
      color: "#FFFFFF", // white text on dark input
    },
    passwordInput: {
      backgroundImage: "url(data:image/svg+xml;base64,...)", // replace base64 for password icon
      backgroundRepeat: "no-repeat",
      backgroundSize: "1.5rem",
      backgroundPosition: "1rem 50%",
    },
    toggleIcon: {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      cursor: "pointer",
      color: "#7fa7ba", // Grey color for visibility icon
    },
    forgotPasswordLink: {
      color: "#7fa7ba", // grey link color
      textDecoration: "none",
      fontSize: "0.875rem",
    },
    submitButton: {
      backgroundColor: isHovered ? "#37b379" : "#44dc94", // Change on hover
      color: "#2c3d4e", // dark blue text on button
      border: "none",
      borderRadius: "25px",
      padding: "0.75rem 1.5rem",
      fontSize: "1.25rem",
      fontWeight: 600,
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      marginBottom: "1rem", // Adjusted margin for better spacing
      width: "100%",
      textTransform: "uppercase",
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.grid}>
        {forgot ? (
          <Forgot setForgot={setForgot} />
        ) : (
          <div style={styles.register}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={styles.logo}
              width="56"
              height="84"
              viewBox="77.7 214.9 274.7 412"
            >
              <defs>
                <linearGradient id="a" x1="0%" y1="0%" y2="0%">
                  <stop offset="0%" stopColor="#44dc94" />
                  <stop offset="100%" stopColor="#37b379" />
                </linearGradient>
              </defs>
              <path
                fill="url(#a)"
                d="M215 214.9c-83.6 123.5-137.3 200.8-137.3 275.9 0 75.2 61.4 136.1 137.3 136.1s137.3-60.9 137.3-136.1c0-75.1-53.7-152.4-137.3-275.9z"
              />
            </svg>

            <h2 style={styles.h2}>Sign In</h2>

            {!isLoggedIn ? (
              <form className="form" onSubmit={handleLogin}>
                <div style={styles.formField}>
                  <input
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                    type="text"
                    placeholder="info@mailaddress.com"
                    style={{ ...styles.input, ...styles.emailInput }}
                  />
                </div>

                <div style={styles.formField}>
                  <input
                    onChange={(e) =>
                      setValues({ ...values, password: e.target.value })
                    }
                    type={showPassword ? "text" : "password"} // Toggle between text and password
                    placeholder="••••••••••••"
                    style={{ ...styles.input, ...styles.passwordInput }}
                  />
                  <span
                    style={styles.toggleIcon}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </span>
                </div>

                <div style={styles.formField}>
                  <div
                    onClick={() => setForgot(!forgot)} // Toggle forgot password view
                    style={{
                      ...styles.forgotPasswordLink, // Apply same link style
                      cursor: "pointer", // Make it clickable
                    }}
                  >
                    Forgot Password?
                  </div>
                </div>

                <div style={styles.formField}>
                  {assetloading ? (
                    <CircularProgress />
                  ) : (
                    <input
                      type="submit"
                      value="Sign In"
                      style={styles.submitButton}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    />
                  )}
                </div>
              </form>
            ) : (
              <>
                {showTwoFactorOptions && (
                  <div>
                    <div style={styles.formField}>
                      <input
                        type="checkbox"
                        id="twoFactorCheckbox"
                        checked={isTwoFactorEnabled}
                        onChange={() => {
                          setIsTwoFactorEnabled(!isTwoFactorEnabled);
                          setTwoFA(!twoFA);
                        }}
                      />
                      <label
                        htmlFor="twoFactorCheckbox"
                        style={{ color: "#FFFFFF" }}
                      >
                        Enable Two-Factor Authentication
                      </label>
                    </div>
                    <p style={styles.explanation}>
                      This will require you to use a one-time passcode sent to
                      your{" "}
                      <strong style={{ color: "#44dc94" }}>WhatsApp</strong> and{" "}
                      <strong style={{ color: "#44dc94" }}>Email</strong> every
                      time you log in to authenticate you signing in.
                    </p>

                    {result?.TwoFA === 1 && (
                      <form className="form" onSubmit={handleTwoFactorSubmit}>
                        <div style={styles.formField}>
                          <input
                            type="text"
                            placeholder="Enter 2FA code"
                            value={twoFactorCode}
                            onChange={(e) => setTwoFactorCode(e.target.value)}
                            style={styles.input}
                          />
                        </div>

                        <div style={styles.formField}>
                          {resetloading ? (
                            <CircularProgress />
                          ) : (
                            <input
                              type="submit"
                              value="Submit 2FA"
                              style={styles.submitButton}
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                            />
                          )}
                        </div>
                      </form>
                    )}
                    {result?.TwoFA !== 1 && (
                      <div style={styles.formField}>
                        <button
                          onClick={handleContinue}
                          style={styles.submitButton}
                        >
                          SKIP 2FA
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {!isLoggedIn && (
              <p>
                Already have an account?{" "}
                <a href="/signup" style={styles.link}>
                  Sign up
                </a>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SignUp;
