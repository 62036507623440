import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { AppContext } from "../../AppContext";
import { encryptSession } from "../../api";
import moment from "moment";
import { DefaultContextDateRange } from "./Functions";

const SearchableDropdown = ({ locations, storedValues }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(
    storedValues ? [storedValues] : [locations[0]]
  );
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const { context, setContext, setVendorImage, setLocd ,locd} =
    useContext(AppContext);
  const [startTime, endTime] = DefaultContextDateRange("day");
  const dropdownRef = useRef(null);
  const [dropdownHeight, setDropdownHeight] = useState(300);

  const filteredOptions = locations?.filter((option) =>
    option.VendorLocationName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOnChange = useCallback(
    (newValues) => {
      let updateValue;

      if (newValues.length === 0) {
        // If no options are selected, use a default value
        updateValue = {
          VendorLocationID: 0,
          VendorLocationIDs: "",
          VendorLocationName: "",
          VendorID: encryptSession.getItem("ci"),
        };
      } else if (newValues.length === 1) {
        // If only one option is selected, use its values
        const selectedOption = newValues[0];
        updateValue = {
          ...selectedOption,
        };
      } else {
        // If multiple options are selected
        const vendorLocationNames = newValues
          .map((v) => v.VendorLocationName)
          .join(", ");
        const vendorLocationIDs = newValues
          .map((v) => v.VendorLocationID)
          .join(",");
        updateValue = {
          VendorLocationID: 0,
          VendorLocationIDs: vendorLocationIDs,
          VendorLocationName: `${newValues.length} Stations Selected`,
          VendorID: encryptSession.getItem("ci"),
          ImageID: newValues[0].ImageID,
          TradingName: newValues[0].TradingName,
          ... newValues[0],
        };
      }

      setContext((prevContext) => ({
        ...prevContext,
        ...updateValue,
      }));
      setVendorImage(updateValue.ImageID);
      localStorage.setItem("values", JSON.stringify(updateValue));
      setLocd(updateValue);
      encryptSession.setItem("cn", updateValue.TradingName);
      encryptSession.setItem("cim", updateValue.ImageID);
      encryptSession.setItem("locd", updateValue.locd);
      encryptSession.setItem("ci", updateValue.VendorID);
    },
    [setContext, setVendorImage, setLocd]
  );

console.log('locd',locd)


  const handleDateChange = useCallback(
    (start, end) => {
      setContext((prevContext) => ({
        ...prevContext,
        DateRange: [start, end],
      }));
    },
    [setContext]
  );

  useEffect(() => {
    if (!context?.DateRange) {
      setContext((prevContext) => ({
        ...prevContext,
        DateRange: [startTime, endTime],
      }));
    }
  }, [context, setContext, startTime, endTime]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (isVisible) {
      const calculateHeight = () => {
        const windowHeight = window.innerHeight;
        const dropdownTop =
          dropdownRef.current?.getBoundingClientRect().top || 0;
        const maxHeight = windowHeight - dropdownTop - 20;
        setDropdownHeight(Math.max(300, maxHeight));
      };

      calculateHeight();
      window.addEventListener("resize", calculateHeight);

      return () => window.removeEventListener("resize", calculateHeight);
    }
  }, [isVisible]);

  const renderDateInputs = () => {
    const startType = context?.timeRange === "time" ? "datetime-local" : "date";
    const endType = context?.timeRange === "time" ? "datetime-local" : "date";
    return (
      <>
        {context?.timeRange !== "endDate" && (
          <input
            type={startType}
            value={moment(
              new Date(context?.DateRange?.[0] || startTime)
            ).format(
              startType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
            )}
            onChange={(e) =>
              handleDateChange(
                e.target.value,
                context?.DateRange?.[1] || endTime
              )
            }
            placeholder="Start Date"
            className="form-control fc-datepicker"
            style={{
              width: "200px",
              borderRadius: "10px",
              padding: "8px",
              boxSizing: "border-box",
              marginRight: "10px",
            }}
          />
        )}
        <input
          type={endType}
          value={moment(new Date(context?.DateRange?.[1] || endTime)).format(
            endType === "datetime-local" ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD"
          )}
          onChange={(e) =>
            handleDateChange(
              context?.DateRange?.[0] || startTime,
              e.target.value
            )
          }
          placeholder="End Date"
          className="form-control fc-datepicker"
          style={{
            width: "200px",
            padding: "8px",
            borderRadius: "10px",
            boxSizing: "border-box",
            marginRight: "10px",
          }}
        />
      </>
    );
  };

  const handleOptionChange = (option) => {
    let newSelectedOptions;
    if (isMultiSelect) {
      newSelectedOptions = selectedOptions.some(
        (item) => item.VendorLocationID === option.VendorLocationID
      )
        ? selectedOptions.filter(
            (item) => item.VendorLocationID !== option.VendorLocationID
          )
        : [...selectedOptions, option];
    } else {
      newSelectedOptions = [option];
      setIsVisible(false);
    }
    setSelectedOptions(newSelectedOptions);
    handleOnChange(newSelectedOptions);
    setSearchTerm("");
  };

  const toggleMultiSelect = () => {
    setIsMultiSelect(!isMultiSelect);
    if (!isMultiSelect) {
      setSelectedOptions([]);
      handleOnChange([]);
    } else {
      setSelectedOptions([selectedOptions[0] || locations[0]]);
      handleOnChange([selectedOptions[0] || locations[0]]);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {context.timeRange === "nodate" ? null : renderDateInputs()}
      {context?.station !== "none" && (
        <div style={{ position: "relative", width: "200px" }} ref={dropdownRef}>
          <input
            type="text"
            value={
              selectedOptions.length === 0
                ? ""
                : selectedOptions.length === locations.length
                ? "All stations"
                : selectedOptions
                    .map((option) => option.VendorLocationName)
                    .join(", ")
            }
            className="form-control fc-datepicker"
            onClick={() => setIsVisible(!isVisible)}
            placeholder="Select option(s)"
            style={{
              width: "100%",
              padding: "8px",
              boxSizing: "border-box",
              borderRadius: "10px",
            }}
            readOnly
          />
          {isVisible && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: `${dropdownHeight}px`,
                border: "1px solid #ccc",
                zIndex: 1000,
                backgroundColor: "#FFF",
                boxSizing: "border-box",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#FFF",
                  zIndex: 1001,
                  padding: "8px",
                  borderBottom: "1px solid #ccc",
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="multiSelect"
                    checked={isMultiSelect}
                    onChange={toggleMultiSelect}
                    style={{ marginRight: "8px" }}
                  />
                  <label
                    htmlFor="multiSelect"
                    style={{ color: "#333", userSelect: "none" }}
                  >
                    Select Multiple
                  </label>
                </div> */}
                <input
                  type="text"
                  className="form-control fc-datepicker"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "8px",
                    boxSizing: "border-box",
                  }}
                />
              </div>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  overflowY: "auto",
                  flexGrow: 1,
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <style>
                  {`
                    ul::-webkit-scrollbar {
                      display: none;
                    }
                  `}
                </style>
                {filteredOptions.map((option) => (
                  <li
                    key={option.VendorLocationID}
                    onClick={() => handleOptionChange(option)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      backgroundColor: "#FFF",
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={isMultiSelect ? "checkbox" : "radio"}
                      checked={selectedOptions.some(
                        (item) =>
                          item.VendorLocationID === option.VendorLocationID
                      )}
                      onChange={() => handleOptionChange(option)}
                      style={{ marginRight: "10px" }}
                    />
                    {option.VendorLocationName}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
